html,
body {
  height: 100%;
}

mat-sidenav-container {
  height: calc(100vh - 4rem);
}

.main-wrapper {
  display: flex;
  min-height: calc(100vh - 4rem);
  flex-direction: column;
}

.main-content {
  flex: 1;
}

body {
  margin: 0;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-icons/iconfont/material-icons.woff2") format("woff2"), url("~material-icons/iconfont/material-icons.woff") format("woff");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-icons/iconfont/material-icons-outlined.woff2") format("woff2"), url("~material-icons/iconfont/material-icons-outlined.woff") format("woff");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-icons/iconfont/material-icons-round.woff2") format("woff2"), url("~material-icons/iconfont/material-icons-round.woff") format("woff");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-icons/iconfont/material-icons-sharp.woff2") format("woff2"), url("~material-icons/iconfont/material-icons-sharp.woff") format("woff");
}
.material-icons-sharp {
  font-family: "Material Icons Sharp";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

@font-face {
  font-family: "Material Icons Two Tone";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-icons/iconfont/material-icons-two-tone.woff2") format("woff2"), url("~material-icons/iconfont/material-icons-two-tone.woff") format("woff");
}
.material-icons-two-tone {
  font-family: "Material Icons Two Tone";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

.green-snackbar {
  background: var(--primary);
  color: white;
  margin-bottom: 4rem !important;
}

.green-snackbar button {
  background-color: var(--primary-dark);
  color: white;
  border: none;
  margin-bottom: 4rem !important;
}

.red-snackbar {
  background: var(--alert);
  color: white;
  margin-bottom: 4rem !important;
}

.red-snackbar button {
  background-color: var(--alert-dark);
  color: white !important;
  border: none;
  margin-bottom: 4rem !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  /*click effect color change*/
  background-color: var(--primary) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primary);
  /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary);
  /*outer ring color change*/
}

.leaflet-custom-popup .leaflet-popup-content-wrapper {
  border-radius: 0;
}
.leaflet-custom-popup .leaflet-popup-content {
  margin: 0;
}
.leaflet-custom-popup .leaflet-popup-content h2 {
  background-color: var(--primary);
  color: white;
  padding: 1rem;
  margin: 0;
  margin-bottom: 1rem;
}
.leaflet-custom-popup .leaflet-popup-content div {
  margin: 0.5rem;
}
.leaflet-custom-popup a.leaflet-popup-close-button {
  color: white !important;
}

.custom-timepicker .active {
  background-color: var(--primary) !important;
}
.custom-timepicker .clock-face__clock-hand,
.custom-timepicker .clock-face__clock-hand::after {
  background-color: var(--primary) !important;
}
.custom-timepicker .clock-face__clock-hand::before {
  border-color: var(--primary) !important;
}
.custom-timepicker header {
  background-color: var(--primary) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

mat-card.mat-card {
  border-radius: 0.25rem;
  box-shadow: 0rem 0.5rem 2rem rgba(149, 157, 165, 0.2) !important;
}

button.mat-button, button.mat-raised-button, button.mat-flat-button {
  border-radius: 2rem;
  text-transform: uppercase;
}

.dato-individual-card {
  width: 100%;
  color: white;
  text-align: center;
  box-shadow: 0rem 0rem 1rem rgba(149, 157, 165, 0.2) !important;
  border-radius: 0.5rem;
}
.dato-individual-card-primary .header {
  background-color: var(--primary);
}
.dato-individual-card-primary .value {
  color: var(--primary);
  background-color: white;
}
.dato-individual-card .header {
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 1rem 2rem 1rem;
  font-size: 1rem;
}
.dato-individual-card .value {
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1.6rem 2rem 1.6rem;
  font-size: 2rem;
  transition: 0.3s;
}
.dato-individual-card .icon {
  padding: 0.5rem;
  margin: 0.5rem auto 0rem;
}
.dato-individual-card .title {
  margin-top: 1rem;
}
.dato-individual-card mat-icon {
  transform: scale(2);
  transition: 0.3s;
}

.dato-individual-card:hover mat-icon {
  transform: scale(2.2);
}
.dato-individual-card:hover .value {
  font-size: 2.2rem;
}

/* Clases de uso general */
.flex-grow-1 {
  flex-grow: 1;
}

.color-primary {
  color: var(--primary);
}